"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.preloadFont = void 0;
var _enviro = _interopRequireDefault(require("enviro"));
const getDomain = () => _enviro.default.isQa() ? 'hubspotqa' : 'hubspot';
const getHublet = () => {
  const prefix = 'api';
  if (_enviro.default !== null && _enviro.default !== void 0 && _enviro.default.getHublet()) {
    return `${prefix}-${_enviro.default.getHublet()}`;
  }
  return prefix;
};
const parseFontFamily = fontFamily => fontFamily.replace(/ +/g, '_');
const getFontDownloadUrl = family => `https://${getHublet()}.${getDomain()}.com/_hcms/googlefonts/${parseFontFamily(family)}/regular.woff2`;

/** preload a regular/fallback font so that the browser has chance to cache even if it is used consumed child iframe */
const preloadFont = fontFamily => {
  const linkEl = document.createElement('link');
  linkEl.id = 'live-chat-font-preload';
  linkEl.rel = 'preload';
  linkEl.href = getFontDownloadUrl(fontFamily);
  linkEl.as = 'font';
  linkEl.type = 'font/woff2';
  linkEl.crossOrigin = 'anonymous';
  document.head.appendChild(linkEl);
};
exports.preloadFont = preloadFont;